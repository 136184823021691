import gsap, { Power4 } from "imports/gsap";
import screenings from "site/screenings";
import siteCommon from "site/site-common";
let overlay = {
  overlayIsOpen: false,
  rsvpId: "",
  youtubeId: "",
  yt: null,
  init: function () {
    $(window).on("resize", overlay.resize);
    $(".overlay-hook").on("click", overlay.doOverlayHandle);
    $("#overlay .overlay-x").on("click", overlay.doOverlayClose);
    $(document).keyup(function (e) {
      if (overlay.overlayIsOpen) {
        if (e.keyCode === 27) {
          // escape key
          overlay.doOverlayClose();
        }
      }
    });
  }, //init

  doOverlayHandle: function () {
    let overlayType = $(this).attr("data-overlay");
    let youtubeId = $(this).attr("data-youtubeId");
    let rsvpId = $(this).attr("data-rsvp-id");

    // karl added 4/3/24
    let hostedVideoUrl = $(this).attr("data-hosted-video-url");
    // console.log("hosted video url is ", hostedVideoUrl);
    // customDropdowns.init();

    overlay.doOverlayHandleSetValues(
      overlayType,
      youtubeId,
      hostedVideoUrl,
      rsvpId
    );
  },

  doOverlayHandleSetValues: function (
    overlayType,
    youtubeId,
    hostedVideoUrl,
    rsvpId
  ) {
    overlay.overlayIsOpen = false;
    overlay.youtubeId = youtubeId;

    // karl added 4/3/24
    overlay.hostedVideoUrl = hostedVideoUrl;

    overlay.rsvpId = rsvpId;
    overlay.doOverlayOpen("overlay-" + overlayType);
  },

  doOverlayOpen: function (overlayTemplate) {
    if (overlay.overlayIsOpen === false) {
      //hide all, show
      $(".overlay-section").hide();
      $("#" + overlayTemplate).show();

      //set fade in
      $("#overlay").css("opacity", 0);
      $("#overlay").show();

      $(".overlay-x .close-button").removeClass("active");

      //special
      if (overlayTemplate === "overlay-rsvp") {
        screenings.populateRsvpOverlay(overlay.rsvpId);
        overlay.doOverlayOpenReveal();
      } else if (overlayTemplate === "overlay-trailer-api") {
        overlay.doOverlayOpenReveal();
        if (overlay.youtubeId != null) {
          if (globalCookiesDisabledOptOut) {
            $('#ytplayer').html('<div class="flexc"><div class="rte"><p class="disabled">Watch on <a href="https://www.youtube.com/watch?v=' + overlay.youtubeId + '" target="_blank">YouTube</a></p></div></div>');
          } else {
            overlay.yt = new YT.Player("ytplayer", {
              height: "390",
              width: "640",
              videoId: overlay.youtubeId,
              host: "https://www.youtube-nocookie.com",
              playerVars: {
                origin: window.location.host,
                autoplay: 1,
                controls: 1,
                playsinline: 1,
                modestbranding: 1,
                rel: 0,
              },
              events: {
                onReady: overlay.onPlayerReady,
                onStateChange: overlay.onPlayerStateChange,
              },
            });
          }
        } else if (overlay.hostedVideoUrl != null) {
          // Create a video element
          var video = document.createElement("video");
          // Set video source
          video.src = overlay.hostedVideoUrl; // Update the path to your video file
          // Set video attributes
          video.controls = true; // Enable video controls (play, pause, etc.)
          // Get the existing div element
          var divContainer = document.getElementById("ytplayer");
          // Append the video element to the div
          divContainer.appendChild(video);
          // Play the video
          video.play();
        }
      }
    }
  },

  doOverlayOpenReveal: function () {
    gsap.fromTo(
      $("#overlay"),
      1,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0,
        ease: Power4.easeInOut,
        onComplete: overlay.doOverlayOpenReveal2,
      }
    );
    overlay.resize();

    //scroll to top of page if not fixed
    setTimeout(function () {
      if ($("body").hasClass("screenings-scroller")) {
        siteCommon.scOpenTopOfPage();
      }
    }, 500);
  },
  doOverlayOpenReveal2: function () {
    overlay.overlayIsOpen = true;
    overlay.resize();
  },

  doOverlayClose: function () {
    $(".overlay-x .close-button").addClass("active");
    gsap.fromTo(
      $("#overlay"),
      0.5,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        delay: 0,
        ease: Power4.easeInOut,
        onComplete: overlay.doOverlayClose2,
      }
    );
  },
  doOverlayClose2: function () {
    $("#overlay").hide();
    try {
      if (overlay.yt) {
        overlay.yt.stopVideo();
      }
    } catch (e) {}

    //clear object
    $("#overlay-trailer-api .overlay-video-wrapper").html(
      '<div id="ytplayer"></div>'
    );

    overlay.overlayIsOpen = false;
  },

  resize: function (evt) {
    //BGS
    //trace('overlay resize');
    let docWidth = $(window).width();
    let docHeight = $(window).height();

    //TRAILER
    overlay.resizeYTOverlay("#overlay-trailer-api");

    //screenings
    if ($("body").hasClass("screenings")) {
      let rsvpH = $("#overlay-rsvp-hook").height();
      if (docWidth < 800 || rsvpH > docHeight - 100) {
        $("body").addClass("screenings-scroller");
      } else {
        $("body").removeClass("screenings-scroller");
      }
    }
  }, //resize

  resizeYTOverlay: function (tid) {
    //trace(tid);
    var yt_box_w = $(tid).width();
    var yt_box_h = $(tid).height();
    var padding = 0; //extra padding
    var yt_rat = 640 / 360;
    var yt_w = yt_box_w - padding;
    var yt_h = yt_w / yt_rat;
    if (yt_h > yt_box_h) {
      yt_h = yt_box_h - padding;
      yt_w = yt_h * yt_rat;
    }

    var caption_h = 0;
    if ($(tid + " .overlay-video-wrapper").hasClass("hascaption")) {
      caption_h = $(tid + " .overlay-video-caption").height();
      caption_h += 12;
      $(tid + " .overlay-video-caption").css("bottom", -caption_h);
      $(tid + " .overlay-video-caption").css("width", yt_w);
    }

    var yt_t = (yt_box_h - yt_h) / 2;
    var yt_l = (yt_box_w - yt_w) / 2;
    if (yt_t < 0) {
      yt_t = 0;
    }
    $(tid + " .overlay-video-wrapper").css({
      // was causing issues so commented out and replaced with css
      // top: yt_t,
      // left: yt_l,
      // width: yt_w,
      // height: yt_h,
    });
  },

  onPlayerReady(e) {

  },
  onPlayerStateChange(e) {

  },
};
export default overlay;
