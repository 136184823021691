import overlay from "site/overlay";
import siteAOS from "site/site.aos";

const dayjs = require("imports/dayjs");
const AdvancedFormat = require("imports/dayjs/plugin/advancedFormat");
const utc = require("imports/dayjs/plugin/utc");
const timezone = require("imports/dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(AdvancedFormat);

import siteCommon from "site/site-common";
import customDropdowns from "./customDropdowns";
// import addeventatc from "./atc.min.js";

let screenings = {
  screeningIdObject: null,
  screeningsByTheater: null,
  cityList: [],
  clickedSubmitOnce: false,
  isSubmitting: false,
  serverRoot: "",
  init: async function () {
    if ($("body").hasClass("dm")) {
      screenings.serverRoot = "https://api.sony-fyc.dev.realpie.com/api/v1/";
    } else {
      screenings.serverRoot = "https://sony-fyc.rsvp.api.realpie.com/api/v1/";
    }

    if ($("body").hasClass("page-screenings") && screeningsLinkageSlug) {
      // when a film is selected change the url to the corresponding film
      $("#selector-film .div-options").on("click", function () {
        let selected_film = $(
          "#selector-film .div-select-selected-display"
        ).attr("selected_val");
        window.location = "/" + selected_film + "/screenings";
      });

      let apiUrl = `${screenings.serverRoot}films/${screeningsLinkageSlug}/screenings_by_theater/`;
      if (site_segment3) {
        apiUrl = `${screenings.serverRoot}screenings/${site_segment3}/`;
      }

      let doManuallyPopulate = false;
      if (doManuallyPopulate) {
        screenings.manualPopulate();
        screenings.convertToScreeningsListingsDisplay();
      } else {
        $.ajax({
          url: apiUrl,
          type: "GET",
          dataType: "json",
          crossDomain: true,
          success: function (response, textStatus) {
            // console.log("response", response);
            if (site_segment3) {
              let doIt = true;
              if (sunsetSite === "1" && response.private === false) {
                doIt = false;
              }
              if (doIt) {
                response.screenings = [response];
                screenings.screeningsByTheater = [response];
                screenings.convertToScreeningsListingsDisplay(true);
              } else {
                window.location = "/";
              }
            } else {
              screenings.screeningsByTheater = response;
              screenings.convertToScreeningsListingsDisplay(false);
            }
          },
          error: function (jqXHR, textStatus) {
            // if error, and site is sunset, this link does not exist, send them back to home page
            if (site_segment3) {
              if (sunsetSite === "1") {
                window.location = "/";
              }
            }
          },
        });
      }
    }

    // // page-screenings-list
    if ($("body").hasClass("page-screenings-list")) {
      // console.log("page-screenings-list");
      let apiUrl;
      // get the second url segment
      let screeningsListSlug = site_segment2;
      if (screeningsListSlug) {
        apiUrl = `${screenings.serverRoot}screenings/feed/${screeningsListSlug}/`;
      }

      let doManuallyPopulate = false;
      if (doManuallyPopulate) {
        screenings.manualPopulate();
        screenings.convertToScreeningsListingsDisplayForListPage();
      } else {
        $.ajax({
          url: apiUrl,
          type: "GET",
          dataType: "json",
          crossDomain: true,
          success: function (response, textStatus) {
            console.log("response", response);

            if (response.title_override != null) {
              $(".screenings-list-title").html(response.title_override);
            }
            screenings.screeningsByTheaterList = response.screening_set;

            // console.log(
            //   "screenings.screeningsByTheaterList",
            //   screenings.screeningsByTheaterList
            // );

            screenings.convertToScreeningsListingsDisplayForListPage(false);
            // }
          },
          error: function (jqXHR, textStatus) {
            // if error, and site is sunset, this link does not exist, send them back to home page
            if (site_segment3) {
              if (sunsetSite === "1") {
                window.location = "/";
              }
            }
          },
        });
      }
    }
  },

  topNavChangeFilmMobile: function () {
    let val = $("#topnav-film-change-mobile").val();
    if (val) {
      window.location = "/" + val + "/screenings";
    }
  },
  topNavChangeFilmDesktop: function () {
    let val = $("#topnav-film-change-desktop").val();
    if (val) {
      window.location = "/" + val + "/screenings";
    }
  },

  convertToScreeningsListingsDisplay: function (individualLink) {
    // strip out guilds/awards

    if (screenings.screeningsByTheater) {
      for (let i = 0; i < screenings.screeningsByTheater.length; i++) {
        let obj = screenings.screeningsByTheater[i];
        let screeningsFiltered = [];
        for (let ii = 0; ii < obj.screenings.length; ii++) {
          let obj2 = obj.screenings[ii];
          let inAwards = false;
          let inGuilds = false;
          for (let iii = 0; iii < obj2.sites.length; iii++) {
            let site = obj.screenings[ii].sites[iii];
            if (site.slug === "awards") {
              inAwards = true;
            } else if (site.slug === "guilds") {
              inGuilds = true;
            }
          }
          // push screenings into awards
          if (site_isGuilds) {
            if (inGuilds) {
              screeningsFiltered.push(
                screenings.screeningsByTheater[i].screenings[ii]
              );
            }
          } else {
            if (inAwards) {
              screeningsFiltered.push(
                screenings.screeningsByTheater[i].screenings[ii]
              );
            }
          }
        }
        obj.screenings = screeningsFiltered;
      }
    }

    let finalHtml = "";
    if (screenings.screeningsByTheater) {
      for (let i = 0; i < screenings.screeningsByTheater.length; i++) {
        let obj = screenings.screeningsByTheater[i];
        //screenings
        let screeningsHtml = "";
        for (let ii = 0; ii < obj.screenings.length; ii++) {
          let obj2 = obj.screenings[ii];
          if (obj2) {
            let dtString = obj2.local_datetime;
            dtString = dtString.slice(0, -6); //kill timezone for display
            let dt = new Date(dtString);
            let dtDateDay = dayjs(dt).format("dddd");
            let dtDateMonth = dayjs(dt).format("MMMM");
            let dtDateDayNum = dayjs(dt).format("DD");
            let dtTime = dayjs(dt).format("hh:mm A");
            let isOpen = obj2.button_status === "enabled";
            let btnLabel = obj2.button_label;
            let btnClass = isOpen ? "open overlay-hook" : "closed";

            //qa
            let defaultCommentsIcon = $("#default-comments-icon").html();
            let qaIcon = "";
            if (obj2.notes) {
              if (
                obj2.notes.qa &&
                (obj2.notes.qa.title || obj2.notes.qa.description)
              ) {
                qaIcon = `<div class="qaIcon">
                                <div class="icon">${defaultCommentsIcon}</div>
                                <span>Q&A</span>
                            </div>`;
              }
            }
            let temp2 = `
                        <div class="item rte no-max-width">
                            <h4>${dtDateDay} <span class="desktop">/</span> <span class="mobile"><br></span> <span>${dtDateMonth} ${dtDateDayNum}</span></h4>
                            <p>${dtTime} ${obj2.local_timezone}</h4>
                            <div class="rsvp">
                                <button class="general-button ${btnClass}" data-overlay='rsvp' data-rsvp-id="${obj2.id}">${btnLabel}</button>
                                ${qaIcon}
                            </div>
                        </div>
                    `;
            screeningsHtml += temp2;
          }
        }

        // theater row
        if (obj.screenings.length && obj.theater) {
          //city list
          screenings.cityList.push({
            label: obj.theater.city.name,
            slug: obj.theater.city.slug,
          });
          let screenings_obj = obj.screenings[0];
          // get address and format it into a google maps url
          let theater_address = screenings.convertTextToBr(obj.theater.address);
          let addressForGoogleMaps = theater_address.replace(/<br>/g, " ");
          addressForGoogleMaps = addressForGoogleMaps.replace(/ /g, "+");
          let googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressForGoogleMaps}`;
          let notes_list_html = "";

          // console.log("screenings_obj.notes_list", screenings_obj.notes_list);

          // if obj.screenings.notes_list is not empty, then we will add them to notes_list_html
          if (
            screenings_obj.notes_list &&
            screenings_obj.notes_list.length > 0 &&
            (screenings_obj.notes_list[0].title != "" ||
              screenings_obj.notes_list[0].description != "")
          ) {
            // console.log("screenings_obj.notes_list", screenings_obj.notes_list);
            let defaultReception = $("#default-reception").html();
            let defaultQa = $("#default-qa").html();
            for (let iii = 0; iii < screenings_obj.notes_list.length; iii++) {
              let note_object = screenings_obj.notes_list[iii];
              notes_list_html += `<div class="note ${note_object.note_type}">`;
              notes_list_html += `<div class="icon">`;
              if (note_object.note_type === "reception") {
                notes_list_html += defaultReception;
              } else {
                notes_list_html += defaultQa;
              }
              notes_list_html += `</div>`; // icon
              notes_list_html += `<div class="copy">`;
              notes_list_html += `<h3>${note_object.title}</h3>`;
              notes_list_html += `<div class="p2">${note_object.description}</div>`;
              notes_list_html += `</div>`; // copy
              notes_list_html += `</div>`; // note
            }
          }

          let address = screenings.convertTextToBr(obj.theater.address);
          let phone = obj.theater.phone ? obj.theater.phone : "";
          let theaterTitle = obj.theater.title ? obj.theater.title : "";
          if (!theaterTitle) {
            theaterTitle = obj.theater.name ? obj.theater.name : "";
          }
          let defaultLocation = $("#default-location").html();
          let temp = `
            <div class="theater-row theater-row-${obj.theater.city.slug}">
              <div class="col-theaters rte no-max-width" >
              <div class="location">
              <div class="icon">
                ${defaultLocation}
              </div>
              <div class="copy">
                <div class="p1">
                  <h3>${theaterTitle}</h3>
                </div>
                <div class="p2">
                  <p class="txt-address">${address}</p>
                  <p class="phone">${phone}</p>
                </div>

                <div class="link">
                  <a href="${googleMapsUrl}" target="_blank">Directions</a>
                </div>
                  </div>
                  </div>

                  <div class="notes-list">
                  ${notes_list_html}
                  </div>
              </div>

              <div class="col-screenings">${screeningsHtml}</div>
            </div>
                `;
          finalHtml += temp;
        }
      }
    }

    // if individual link, and there is nothing there - bounce to home page
    if (individualLink) {
      if (finalHtml === "") {
        window.location = "/";
      }
    }

    //city list cleanup
    //screenings.cityList
    screenings.cityList = screenings.unique(screenings.cityList);

    // for each city in the cityList, create an option for the dropdown
    let citiesHtml = '<div class="div-option" data-value="all">All</div>';
    for (let i = 0; i < screenings.cityList.length; i++) {
      let obj = screenings.cityList[i];
      citiesHtml += `<div class="div-option" data-value="${obj.slug}">${obj.label}</div>`;
    }

    // handle city click
    $("#selector-city .div-options").on("click touchstart", function (e) {
      let selected_city = $(e.target).attr("data-value");
      let selected_city_label = $(e.target).html();

      // hide the dropdown
      $("#selector-city .field-box").removeClass("over");
      $("#selector-city .div-select").css("height", 0);

      // update the selected city
      $("#selector-city .div-select-selected-display").html(
        selected_city_label
      );

      // hide all theater rows that dont match the selected city
      if (selected_city === "all") {
        $(".theater-row").show();
      } else {
        $(".theater-row").hide();
        $(".theater-row-" + selected_city).show();
      }
      siteAOS.refreshAOS();
    });

    $("#selector-city .div-options").html(citiesHtml);

    if (finalHtml === "") {
      finalHtml =
        "<div style='margin-top:50px' class='rte'><p>Coming soon.</p></div>";
    }
    $("#all-listings").html(finalHtml);
    $("#all-listings .overlay-hook").on("click", overlay.doOverlayHandle);
    siteAOS.refreshAOS();

    // trigger popup if deep linked
    if (site_segment3) {
      let overlayType = "rsvp";
      let youtubeId = null;
      let rsvpId = site_segment3;
      overlay.doOverlayHandleSetValues(overlayType, youtubeId, null, rsvpId);
    }
  },

  convertToScreeningsListingsDisplayForListPage: function (individualLink) {
    // strip out guilds/awards
    let screeningsListFiltered = [];
    if (screenings.screeningsByTheaterList) {
      let screeningsFiltered = [];
      for (let i = 0; i < screenings.screeningsByTheaterList.length; i++) {
        let obj = screenings.screeningsByTheaterList[i].screening;

        // ignore the obj.order prop from the api, and use the index of the array. the api seems to sort it out
        // obj.order = screenings.screeningsByTheaterList[i].order;
        obj.order = i;

        console.log("obj", obj);
        let inAwards = false;
        let inGuilds = false;
        for (let ii = 0; ii < obj.sites.length; ii++) {
          if (obj.sites[ii].slug === "awards") {
            inAwards = true;
          }
          if (obj.sites[ii].slug === "guilds") {
            inGuilds = true;
          }
        }

        // push screenings into awards
        if (site_isGuilds) {
          if (inGuilds) {
            screeningsListFiltered.push(obj);
          }
        } else {
          if (inAwards) {
            screeningsListFiltered.push(obj);
          }
        }
      }
      // console.log("screeningsFiltered", screeningsListFiltered);
    }

    let finalHtml = "";

    if (screeningsListFiltered) {
      for (let i = 0; i < screeningsListFiltered.length; i++) {
        let obj = screeningsListFiltered[i];
        console.log("obj", obj);

        // get theater info
        async function getTheaterInfo(theaterUrl) {
          let theaterInfo = await $.ajax({
            url: theaterUrl,
            type: "GET",
            dataType: "json",
            crossDomain: true,
            success: function (response, textStatus) {
              return response;
            },
          });
          return theaterInfo;
        }

        obj.theaterInfo = getTheaterInfo(obj.theater);
        // console.log("obj", obj);

        async function formatTheaterInfo(obj) {
          let theaterInfo = await obj.theaterInfo;
          // console.log("theaterInfo", theaterInfo);

          let screeningsHtml = "";

          let filmTitle = obj.film.title;

          let dtString = obj.local_datetime;
          dtString = dtString.slice(0, -6); //kill timezone for display
          let dt = new Date(dtString);
          let dtDateDay = dayjs(dt).format("dddd");
          let dtDateMonth = dayjs(dt).format("MMMM");
          let dtDateDayNum = dayjs(dt).format("DD");
          let dtTime = dayjs(dt).format("hh:mm A");
          let isOpen = obj.button_status === "enabled";
          let btnLabel = obj.button_label;
          let btnClass = isOpen ? "open overlay-hook" : "closed";

          //qa
          let defaultCommentsIcon = $("#default-comments-icon").html();
          let qaIcon = "";
          if (obj.notes) {
            if (
              obj.notes.qa &&
              (obj.notes.qa.title || obj2.notes.qa.description)
            ) {
              qaIcon = `<div class="qaIcon">
                            <div class="icon">${defaultCommentsIcon}</div>
                            <span>Q&A</span>
                        </div>`;
            }
          }
          let temp2 = `

                    <div class="item rte no-max-width">
                    <h3><span style="font-weight:bold">${filmTitle}</span></h3>
                        <h4>${dtDateDay} <span class="desktop">/</span> <span class="mobile"><br></span> <span>${dtDateMonth} ${dtDateDayNum}</span></h4>
                        <p>${dtTime} ${obj.local_timezone}</h4>
                        <div class="rsvp">
                            <button class="general-button ${btnClass}" data-overlay='rsvp' data-rsvp-id="${obj.id}">${btnLabel}</button>
                            ${qaIcon}
                        </div>
                    </div>
                `;

          if (theaterInfo != null) {
            let theater_address = theaterInfo.address;
            //
            let addressForGoogleMaps = theater_address.replace(/ /g, "+");
            let googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressForGoogleMaps}`;
            let notes_list_html = "";

            // if obj.screenings.notes_list is not empty, then we will add them to notes_list_html
            if (
              obj.notes_list &&
              obj.notes_list.length > 0 &&
              (obj.notes_list[0].title != "" ||
                obj.notes_list[0].description != "")
            ) {
              // console.log("screenings_obj.notes_list", screenings_obj.notes_list);
              let defaultReception = $("#default-reception").html();
              let defaultQa = $("#default-qa").html();
              for (let iii = 0; iii < obj.notes_list.length; iii++) {
                let note_object = obj.notes_list[iii];
                notes_list_html += `<div class="note ${note_object.note_type}">`;
                notes_list_html += `<div class="icon">`;
                if (note_object.note_type === "reception") {
                  notes_list_html += defaultReception;
                } else {
                  notes_list_html += defaultQa;
                }
                notes_list_html += `</div>`; // icon
                notes_list_html += `<div class="copy">`;
                notes_list_html += `<h3>${note_object.title}</h3>`;
                notes_list_html += `<div class="p2">${note_object.description}</div>`;
                notes_list_html += `</div>`; // copy
                notes_list_html += `</div>`; // note
              }
            }

            // let address = screenings.convertTextToBr(obj.theaterInfo.address);
            let address = theaterInfo.address;
            let phone = theaterInfo.phone ? theaterInfo.phone : "";
            let theaterTitle = theaterInfo.title ? theaterInfo.title : "";
            if (!theaterTitle) {
              theaterTitle = theaterInfo.name ? theaterInfo.name : "";
            }
            let defaultLocation = $("#default-location").html();
            let temp = `
            <div class="theater-row" data-order="${obj.order}">
            
              <div class="col-theaters rte no-max-width" >
              <div class="location">
              <div class="icon">
                ${defaultLocation}
              </div>
              <div class="copy">
                <div class="p1">
                  <h3>${theaterTitle}</h3>
                </div>
                <div class="p2">
                  <p class="txt-address">${address}</p>
                  <p class="phone">${phone}</p>
                </div>

                <div class="link">
                  <a href="${googleMapsUrl}" target="_blank">Directions</a>
                </div>
                  </div>
                  </div>

                  <div class="notes-list">
                  ${notes_list_html}
                  </div>
              </div>

              <div class="col-screenings">${temp2}</div>

              
            </div>
                `;
            finalHtml += temp;
            // screeningsHtml = temp2 +temp;
          }

          // console.log("screeningsHtml", screeningsHtml);
          finalHtml += screeningsHtml;
          // console.log("finalHtml", finalHtml);

          $("#all-listings").html(finalHtml);

          // then sort because we are async
          var $wrapper = $("#all-listings");

          $wrapper
            .find(".theater-row")
            .sort(function (a, b) {
              return +a.dataset.order - +b.dataset.order;
            })
            .appendTo($wrapper);

          $("#all-listings .overlay-hook").on("click", overlay.doOverlayHandle);
          siteAOS.refreshAOS();
        }

        formatTheaterInfo(obj);
      }
    }

    // console.log("finalHtml", finalHtml);

    // if individual link, and there is nothing there - bounce to home page
    if (individualLink) {
      if (finalHtml === "") {
        window.location = "/";
      }
    }

    // if (finalHtml === "") {
    //   finalHtml =
    //     "<div style='margin-top:50px' class='rte'><p>Coming soon.</p></div>";
    // }
    // $("#all-listings").html(finalHtml);

    // trigger popup if deep linked
  },

  unique: function (obj) {
    var uniques = [];
    var stringify = {};
    for (var i = 0; i < obj.length; i++) {
      var keys = Object.keys(obj[i]);
      keys.sort(function (a, b) {
        return a - b;
      });
      var str = "";
      for (var j = 0; j < keys.length; j++) {
        str += JSON.stringify(keys[j]);
        str += JSON.stringify(obj[i][keys[j]]);
      }
      if (!stringify.hasOwnProperty(str)) {
        uniques.push(obj[i]);
        stringify[str] = true;
      }
    }
    return uniques;
  },

  populateRsvpOverlay(rsvpId) {
    //screenings.manualPopulateRsvp()
    let apiUrl = `${screenings.serverRoot}screenings/${rsvpId}/`;
    $.ajax({
      url: apiUrl,
      type: "GET",
      dataType: "json",
      crossDomain: true,
      success: function (response, textStatus) {
        screenings.screeningIdObject = response;
        screenings.populateRsvpOverlay2();
      },
      error: function (jqXHR, textStatus) {},
    });
  },

  populateRsvpOverlay2() {
    let obj = screenings.screeningIdObject;
    let address = screenings.convertTextToBr(obj.theater.address);
    let dtString = obj.local_datetime;
    dtString = dtString.slice(0, -6); //kill timezone for display
    let dt = new Date(dtString);
    let dtDateDay = dayjs(dt).format("dddd");
    let dtDateMonth = dayjs(dt).format("MMMM");
    let dtDateDayNum = dayjs(dt).format("DD");
    let dtTime = dayjs(dt).format("hh:mm A");

    // get address and format it into a google maps url
    let theater_address = screenings.convertTextToBr(obj.theater.address);
    let addressForGoogleMaps = theater_address.replace(/<br>/g, " ");
    addressForGoogleMaps = addressForGoogleMaps.replace(/ /g, "+");
    let googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressForGoogleMaps}`;

    //affiliate dropdown
    let affiliateDropdown = "";
    for (let i = 0; i < obj.available_affiliations.length; i++) {
      let tobj = obj.available_affiliations[i];
      affiliateDropdown += `<div class="div-option" value="${tobj.slug}" data-placeholder="${tobj.name}">${tobj.name}</div>`;
    }

    // let driveOn = "";
    // if (obj.theater.notes_list.length > 0) {
    //   driveOn = `<div class="sec sec-driveon">
    //     <div class="data-access">${obj.theater.notes_list[0].title}</div>
    //     <div class="seating-blurb">${obj.theater.notes_list[0].description}</div>
    //   </div>`;
    // }

    let notes_list = "";
    if (obj.notes_list && obj.notes_list.length > 0) {
      for (let iii = 0; iii < obj.notes_list.length; iii++) {
        let tobj = obj.notes_list[iii];
        notes_list += `<div class="sec sec-${tobj.note_type}">`;
        notes_list += `<h5>${tobj.title}</h5>`;
        notes_list += `${tobj.description}`;
        notes_list += `</div>`;
      }
    }

    // let addGuestsButton = ``;
    // if (obj.guests_limit && obj.guests_limit > 0) {
    //   addGuestsButton = `<div id="add-guests-button-holder"><button class="general-button inverted" id="add-guests">Add Guest</button></div>`;
    // }

    let showGuestsDropdown = false;

    let guestsDropdown = "";
    let guests_limit = obj.guests_limit;
    if (guests_limit && guests_limit > 0) {
      showGuestsDropdown = true;
      for (let i = 0; i <= guests_limit; i++) {
        let s = "";
        if (i > 1) {
          s = "s";
        }
        guestsDropdown += `<div class="div-option" value="${i}" data-placeholder="${i}">Member + ${i} Guest${s}</div>`;
      }
    } else {
      showGuestsDropdown = false;
    }

    let selectedAffiliation = "";

    // when an affiliation is selected change $selector-affiliation to the corresponding affiliation
    $("#selector-affiliation .div-options").on("click", function () {
      let selected_affiliation = $(
        "#selector-affiliation .div-select-selected-display"
      ).attr("selected_val");
      selectedAffiliation = selected_affiliation;
      $("#selector-affiliation .div-select-selected-display").attr(
        "data-selected-affiliation",
        selected_affiliation
      );
    });

    $("#selector-affiliation").hover(function () {
      $("#selector-affiliation .field-box").addClass("over");
    });

    //FINAL
    let finalHtml = "";
    let defaultSonyLogo = $("#default-sony-logo").html();
    let defaultDropdownArrow = $("#default-dropdown-arrow").html();

    let guestsDropdownHtml = `<div id="selector-guests" class="field field-dropdown-box">
                                  <div class="title">Number of Guests</div>
                                  <div class="field-box solid-hover">
                                    <div class="arrow">
                                      ${defaultDropdownArrow}
                                    </div>
                                    <div class="div-select-selected-display" data-selected-guests="Choose">
                                      Choose
                                    </div>
                                    <div class="div-select" style="height: 0px;">
                                      <div class="div-options">
                                      ${guestsDropdown}
                                      </div>
                                    </div>
                                  </div>
                                </div>`;

    if (showGuestsDropdown == false) {
      guestsDropdownHtml = "";
    }
    finalHtml = `
            <div class="rsvp-inner">
                <div class="scrim"></div>
                <div class="columns">
                    <div class="col-left rte no-max-width">
                    <div class="entry entry-logo clearfix">
                            ${defaultSonyLogo}
                        </div>
                        <h3>RSVP</h3>
                        <div class="film-title mobile">
                          <h5>${obj.film.title}</h5>
                        </div>
                        <div class="sec sec-time mobile">
                            <h5>${dtDateDay}, <span>${dtDateMonth} ${dtDateDayNum}</span></h5>
                            <p>${dtTime} ${obj.local_timezone}</p>
                        </div>

                        <div class="line"></div>
                        <div class="sec sec-film">
                            <p>${obj.theater.name}</p>
                            <p>${address}</p>
                            <a href="${googleMapsUrl}" target="_blank">Directions</a>
                        </div>
                        ${notes_list}
                    </div>
                    <div class="col-right">
                        <div class="film-title desktop">
                          <h5>${obj.film.title}</h5>
                        </div>
                        <div class="sec sec-time desktop">
                            <h5>${dtDateDay}, <span>${dtDateMonth} ${dtDateDayNum}</span></h5>
                            <p>${dtTime} ${obj.local_timezone}</p>
                        </div>
                        <div class="fields">
                            <div class="field-row">
                                <div class="field-row-element">
                                    <input id="first_name" name="first_name" type="text" maxlength="200" minlength="1" required placeholder="First Name*" />
                                </div>
                                <div class="field-row-element">
                                    <input id="last_name" name="last_name" type="text" maxlength="200" minlength="1" required placeholder="Last Name*" />
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="field-row-element">
                                    <input id="phone" name="phone" type="tel" maxlength="50" placeholder="Phone Number (Optional)" />
                                </div>
                                <div class="field-row-element">
                                    <input id="email" name="email" type="email" maxlength="50" required placeholder="Email*"/>
                                </div>
                            </div>
                            <div class="field-row">
                                <div id="selector-affiliation" class="field field-dropdown-box">
                                  <div class="title">Affiliation*</div>
                                  <div class="field-box solid-hover">
                                    <div class="arrow">
                                      ${defaultDropdownArrow}
                                    </div>
                                    <div class="div-select-selected-display" data-selected-affiliation="Choose*">
                                      Choose*
                                    </div>
                                    <div class="div-select" style="height: 0;">
                                      <div class="div-options">
                                      ${affiliateDropdown}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                ${guestsDropdownHtml}


                            </div>
                        </div>
                        <div class="rte no-max-width">
                     
                            <div class="sec sec-finalnote">
                              <p><small>By clicking the SUBMIT BUTTON below, I confirm and consent that my information will be utilized in accordance with the SPE <a target="_blank" href="https://sites.sonypictures.com/corp/privacypolicies/b2b/privacy_ENGLISH.html">Privacy Policy</a> and SPE  <a target="_blank" href="https://sites.sonypictures.com/corp/privacypolicies/b2b/tos_ENGLISH.html">Terms of Use</a> and agree to be bound by them.</small></p>
                            </div>

                            <div class="sec sec-submit">
                                <button id="submit-hook" class="general-button inverted">Submit</button>
                                <div id="rsvp-error-messages">
                                    <p class="error"><small>Please double check the fields above are correct</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        `;

    //FINAL
    $("#overlay-rsvp-hook").html(finalHtml);

    screenings.clickedSubmitOnce = false;
    screenings.isSubmitting = false;
    screenings.clearAllErrors();
    $("#submit-hook").on("click", screenings.doSubmit);
    $("#overlay-rsvp-hook input").on("keyup", screenings.validateForm);
    $("#overlay-rsvp-hook textarea").on("keyup", screenings.validateForm);
    $("#overlay-rsvp-hook select").on("change", screenings.validateForm);

    // add guests
    $("#add-guests").on("click", screenings.addGuest);

    if (showGuestsDropdown == false) {
      guestsDropdownHtml = "";
      $("#selector-affiliation")
        .parent(".field-row")
        .addClass("half-width-desktop");
    }

    // if #selector-guests .div-select-selected-display selected_val is greater than 0, then add the guest-name input fields
    $("#selector-guests .div-options").on("click", function () {
      // hide previous guest fields
      $("#selector-guests .field-row-element").remove();

      let selected_guests = $(
        "#selector-guests .div-select-selected-display"
      ).attr("selected_val");
      if (selected_guests > 0) {
        let guestsFieldsHtml = "";
        for (let i = 0; i < selected_guests; i++) {
          let thtml = `
            <div class="field-row-element">
                <input class="guest-name" name="guest" type="text" maxlength="50" minlength="1" required placeholder="Guest Name*" />
            </div>`;
          guestsFieldsHtml += thtml;
        }

        guestsFieldsHtml = `<div class="guests-fields">${guestsFieldsHtml}</div>`;

        $("#selector-guests").append(guestsFieldsHtml);

        $("#selector-guests .field-row-element .guests-close").off(
          "click",
          screenings.removeGuest
        );
        $("#selector-guests .field-row-element .guests-close").on(
          "click",
          screenings.removeGuest
        );
        $("#selector-guests .field-row-element input").off(
          "keyup",
          screenings.validateForm
        );
        $("#selector-guests .field-row-element input").on(
          "keyup",
          screenings.validateForm
        );
      }
    });

    // init custom dropdowns
    customDropdowns.custDropdownInitPrefix("#selector-guests");
    customDropdowns.custDropdownInitPrefix("#selector-affiliation");
    overlay.resize();
  },

  doSubmit() {
    screenings.clickedSubmitOnce = true;
    let ret = screenings.validateForm();
    if (ret) {
      let answers = screenings.getAnswersObject();
      if (!screenings.isSubmitting) {
        screenings.isSubmitting = true;
        let turl = `${screenings.serverRoot}rsvps/`;
        $.ajax({
          type: "POST",
          dataType: "json",
          contentType: "application/json",
          url: turl,
          // data: answers,
          data: JSON.stringify(answers),
          error: function (xhr, ajaxOptions, thrownError) {
            let data = xhr ? xhr.responseJSON : {};
            if (
              data &&
              // data.error &&
              // data.error.details &&
              // data.error.details.email &&
              data.email[0] === "Email has already registered."
            ) {
              // actually is a success
              screenings.showResult(data, true);
            } else {
              $("#rsvp-error-messages").html(
                '<p class="error"><small>An unknown error has occured.  Please try again later.</small></p>'
              );
              $("#rsvp-error-messages").show();

              screenings.isSubmitting = false;
              console.error(data.status);
              console.error(ajaxOptions);
              console.error(thrownError);
            }
            siteCommon.scOpenTopOfPage();
          },
          success: function (data) {
            //data
            if (!data.error) {
              screenings.showResult(data, false);
            } else {
              console.error(data.error);
              if (
                data.error.details &&
                data.error.details.email &&
                data.error.details.email === "Email has already registered."
              ) {
                // actually is a success
                screenings.showResult(data, true);
              } else {
                let resp = data.error.message;
                $("#rsvp-error-messages").html(
                  '<p class="error"><small>' + resp + "</small></p>"
                );
                $("#rsvp-error-messages").show();
              }
            }
            screenings.isSubmitting = false;
            siteCommon.scOpenTopOfPage();
          },
          beforeSend: function (xhr, settings) {
            //xhr.setRequestHeader("X-CSRFToken", screenings.csrfmiddlewaretoken);
          },
        });
      }
    }
  },

  showResult(data, alreadyRegistered = true) {
    let thankYouHtml;
    if (alreadyRegistered) {
      thankYouHtml = `
            <div class="thanks">
                <div class="rte">
                    <h2>Already Registered!</h2>
                    <p>You are already registered.  We've used your existing reservation.  No new updates have been made to your registration.</p>
                </div>
            </div>
        `;
    } else {
      thankYouHtml = `
            <div class="thanks">
                <div class="rte">
                    <h2>Thank You!</h2>
                    <p>We have received your RSVP.</p>
                </div>
            </div>
        `;
    }
    $(
      "#overlay-rsvp-hook .fields, #overlay-rsvp-hook .sec-submit, #overlay-rsvp-hook .sec-finalnote"
    ).hide();
    $(thankYouHtml).insertBefore("#overlay-rsvp-hook .col-right .film-title");

    // add to calendar functionality
    let dtString = screenings.screeningIdObject.local_datetime;

    dtString = dtString.slice(0, -6); //kill timezone for display
    let dt = new Date(dtString);
    let startDate = dayjs(dt).format("YYYY-MM-DD");
    let startTime = dayjs(dt).format("HH:mm");

    let endTime = dayjs(dt).add(2, "hours").format("HH:mm");

    // let timeZone = dayjs(dtString).tz();
    // var regExp = /\(([^)]+)\)/;
    // var matches = regExp.exec(timeZone["$d"]);
    //matches[1] contains the value between the parentheses

    let theater_address = screenings.convertTextToBr(
      screenings.screeningIdObject.theater.address
    );
    let addressForGoogleMaps = theater_address.replace(/<br>/g, " ");
    addressForGoogleMaps = addressForGoogleMaps.replace(/ /g, "+");
    let googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressForGoogleMaps}`;

    let addToCalendarHtml = `
    <add-to-calendar-button
      name="Screening of: ${screenings.screeningIdObject.film.title}"
      options="'Apple','Google','Outlook.com','Yahoo'"
      location="${googleMapsUrl}"
      startDate="${startDate}"
      endDate="${startDate}"
      startTime="${startTime}"
      endTime="${endTime}"
      timeZone="currentBrowser"
      label="Add to Calendar"
      debug="true"
    ></add-to-calendar-button>
    `;

    // add above for custom css
    // customCss="/assets-site/css/atcb.css"

    $(addToCalendarHtml).insertAfter("#overlay-rsvp-hook .col-right .fields");

    setTimeout(function () {
      // window.atcb_action("initialize");
      // window.addeventasync("initialize");
      // window.addeventatc.refresh();
      // atcb_action(initialize);
    }, 200);
  },

  clearAllErrors: function () {
    $("#overlay-rsvp-hook input").parent().removeClass("error");
    $("#overlay-rsvp-hook #selector-affiliation .field-box").removeClass(
      "error"
    );
    $("#overlay-rsvp-hook #selector-guests .field-box").removeClass("error");

    $("#rsvp-error-messages").hide();
    $("#submit-hook").removeClass("error");
  },

  validateForm: function () {
    let cont = true;
    $(".screenings-popup #signup-state-error").hide();
    if (screenings.clickedSubmitOnce) {
      //clear errors
      screenings.clearAllErrors();

      //check form
      let answers = screenings.getAnswersObject();

      //fn
      if (answers.first_name === "") {
        cont = false;
        $("#first_name").parent().addClass("error");
      }
      //ls
      if (answers.last_name === "") {
        cont = false;
        $("#last_name").parent().addClass("error");
      }
      /*
      //phone
      if (answers.phone === '' || answers.phone.length<10 ) {
          cont=false;
          $('#selector-phone').addClass('error');
      }
      */

      //email
      if (siteCommon.isValidEmail(answers.email) === false) {
        cont = false;
        $("#email").parent().addClass("error");
      }
      //affiliation
      if (answers.affiliation === "") {
        cont = false;
        $("#selector-affiliation .field-box").addClass("error");
      }

      // TBD they are figuring out how to handle guest names

      //num guests
      // if (answers.friend_list.length) {
      //   $("#friends .field-row-element").each(function (index) {
      //     let val = $("input", this).val();
      //     if (!val) {
      //       $("input", this).addClass("error");
      //       cont = false;
      //     }
      //   });
      // }

      // temp guest count - but its not a required field so commented out
      // if (answers.friend_count === null) {
      //   cont = false;
      //   $("#selector-guests .field-box").addClass("error");
      // }

      //legal
      /*
            if (answers.legal === "") {
                cont=false;
                $('#signup-legal').addClass('error');
            }
            */

      //make button grey
      if (cont === false) {
        $("#submit-hook").addClass("error");
        $("#rsvp-error-messages").html(
          '<p class="error"><small>Please double check the fields above are correct</small></p>'
        );
        $("#rsvp-error-messages").show();
      }
    } else {
      cont = false;
    }
    return cont;
  },

  getAnswersObject: function () {
    //var csrfmiddlewaretoken = $( "input[name='csrfmiddlewaretoken']" ).val();
    let answers = {
      screening: screenings.screeningIdObject.id,
      //'csrfmiddlewaretoken':csrfmiddlewaretoken,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      affiliation: "",
      friend_list: [],
      friend_count: 0,
      agreed_to_terms: true,
    };

    answers.first_name = $("#first_name").val();
    answers.last_name = $("#last_name").val();
    answers.phone = $("#phone").val();
    answers.email = $("#email").val();
    // answers.affiliation = $("#affiliation").val();
    answers.affiliation = $(
      "#selector-affiliation .div-select-selected-display"
    ).attr("selected_val");

    // TBD they are changing guest names
    answers.friend_count = $(
      "#selector-guests .div-select-selected-display"
    ).attr("selected_val");

    // friend_list names
    // let friend_list = [];
    // let count = 0;
    // $("input.guest-name").each(function (index) {
    //   let val = $(this).val();

    //   let guestObj = {
    //     name: val,
    //   };
    //   friend_list.push(guestObj);
    // });

    // friend_list names
    let friend_list = [];
    let cnt = 0;
    $("input.guest-name").each(function (index) {
      let val = $(this).val();
      // this should never be needed, but if someone tries to trick the system and manually insert their own values, we block it
      if (cnt < screenings.screeningIdObject.guests_limit) {
        // friend_list.push(val);
        let guestObj = {
          name: val,
        };
        friend_list.push(guestObj);
      }
      cnt++;
    });
    answers.friend_list = friend_list;

    // answers.friend_list = friend_list;
    answers.friend_count = answers.friend_list.length;

    if (answers.affiliation === undefined || answers.affiliation === null) {
      answers.affiliation = "";
    }

    /*
        var friendsNum = parseFloat(answers.friends);
        for (var i=1;i<=friendsNum;i++) {
            answers['guest_name_' + i]=$('#guest-name-' + i + ' input').val();
        }
         */

    return answers;
  },

  convertTextToBr: function (txt) {
    return txt.replace(/(?:\r\n|\r|\n)/g, "<br>");
  },

  manualPopulate: function () {
    screenings.convertToScreeningsListingsDisplay();
  },
  manualPopulateRsvp: function () {},
};
export default screenings;
