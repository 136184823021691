import browser from "imports/browser-detect";
let site = {
  browser: browser(),
  init: function () {
    //add browser name
    $("body").addClass("browser_" + site.browser.name);

    //mobile stuff
    $(".ui-link").removeClass("ui-link");

    //disable img drag
    $("img").on("dragstart", function (event) {
      event.preventDefault();
    });

    if (site.browser.mobile) {
      $("body").addClass("device-mobile");
    } else {
      $("body").addClass("device-notmobile");
    }

    window.addEventListener("resize", site.resize);
    site.resize();
  },
  resize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let ol = (window.innerWidth - 1920) / 2;
    if (ol < 0) ol = 0;
    document.documentElement.style.setProperty("--offsetLeft", `${ol}px`);

    site.siteOrientationChange();
  },
  siteOrientationChange() {
    //if (isMobile) {

    setTimeout(function () {
      //t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
      //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
    }, 100);
    //}
  },

  // isBrowserMobile() {
  //   if ($.browser.mobile) {
  //     $("body").addClass("device_mobile");
  //   } else {
  //     $("body").addClass("device_notmobile");
  //   }
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     $("body").addClass("device_tabmobile");
  //   } else {
  //     $("body").addClass("device_nottabmobile");
  //   }
  // },
};
export default site;
