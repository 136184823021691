//import $ from 'imports/jquery'
import gsap, { Power3 } from "imports/gsap";

let siteCommon = {
  //////////////////////////////////////////////////////////////////////////////
  //////STICK SITE RELATED
  //////////////////////////////////////////////////////////////////////////////
  scGetSiteMode: function () {
    let docWidth = $(document).width();
    let metaBreakbpoint = siteCommon.pxToNum(
      $("#meta-breakpoint").css("padding-top")
    );
    let siteMode = "large";
    if (metaBreakbpoint > 0) {
      if (docWidth < metaBreakbpoint) {
        siteMode = "tiny";
      }
    }
    return siteMode;
  },

  //////////////////////////////////////////////////////////////////////////////
  //////ANALYTICS
  //////////////////////////////////////////////////////////////////////////////
  scAddAnalyticsEventHandle: function (container_id) {
    $(container_id + " .analytics-handle").click(siteCommon.scAnalyticsEvent);
    $(container_id + " .analytics-handle-fb-checkout").click(
      siteCommon.fbTrackEventCheckout
    );
  },
  scAnalyticsEventOnClick: function (l1, l2, l3) {
    siteCommon.gaTrackEvent(l1, l2, l3);
    siteCommon.fbTrackEvent(l1, l2, l3);
    return true;
  },
  scAnalyticsEvent: function () {
    let l1 = $(this).attr("l1");
    let l2 = $(this).attr("l2");
    let l3 = $(this).attr("l3");
    let l4 = $(this).attr("l4");
    let l5 = $(this).attr("l5");
    let l6 = $(this).attr("l6");
    let l7 = $(this).attr("l7");
    let l8 = $(this).attr("l8");
    let l9 = $(this).attr("l9");

    siteCommon.gaTrackEvent(l1, l2, l3);
    siteCommon.fbTrackEvent(l1, l2, l3);
    if (l4 && l4 !== "") {
      siteCommon.gaTrackEvent(l4, l5, l6);
      siteCommon.fbTrackEvent(l4, l5, l6);
    }
    if (l7 && l7 !== "") {
      siteCommon.gaTrackEvent(l7, l8, l9);
      siteCommon.fbTrackEvent(l7, l8, l9);
    }
    return true;
  },
  fbTrackEvent: function (l1, l2, l3) {
    /*
        if (typeof fbq !== 'undefined') {
            fbq('trackCustom', 'MyCustomEvent', {
                l1: l1,
                l2: l2,
                l3: l3
            });
        }
        */
  },
  fbTrackEventCheckout: function () {
    let fbco = $(this).attr("fbco");
    if (typeof fbq !== "undefined") {
      fbq("track", "InitiateCheckout", {
        content_type: fbco,
      });
    }
  },

  gaTrackEvent: function (lev1, lev2, lev3) {
    //ga('send', 'event', lev1,lev2,lev3);  // value is a number.
    if (typeof gtag !== "undefined") {
      gtag("event", "engagement", {
        event_category: lev1,
        event_action: lev2,
        event_label: lev3,
      });
    }
  },

  //////////////////////////////////////////////////////////////////////////////
  //////TXT, NUMBER, ARRAY MANIPULATION
  //////////////////////////////////////////////////////////////////////////////

  pxToNum: function (txt) {
    txt = txt.toString();
    const kill = "px";
    const script = "/" + kill + "/g";
    let t = txt.replace(kill, "");
    t = parseFloat(t);
    return t;
  },
  stripCharacters: function (txt, kill) {
    let script = "/" + kill + "/g";
    //alert(script);
    return txt.replace(kill, "");
  },

  addZeroIfSingle: function (v) {
    v = parseFloat(v);
    if (v < 10) v = "0" + v;
    return v;
  },

  //////////////////////////////////////////////////////////////////////////////
  //////FIELDS
  //////////////////////////////////////////////////////////////////////////////

  isPhotoType: function (val) {
    if (
      val.indexOf(".jpg") >= 0 ||
      val.indexOf(".jpeg") >= 0 ||
      val.indexOf(".gif") >= 0 ||
      val.indexOf(".png") >= 0
    ) {
      return true;
    } else {
      return false;
    }
  },

  isValidPhone: function (p) {
    const phoneRe = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
    const digits = p.replace(/\D/g, "");
    return digits.match(phoneRe) !== null;
  },

  //isnumeric
  isNumeric: function (val) {
    if (isNaN(parseFloat(val))) {
      return false;
    } else {
      return true;
    }
  },

  isValidEmail: function (val) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(val) == false) {
      return false;
    } else {
      return true;
    }
  },

  isValidUSZip: function (sZip) {
    return /^\d{5}(-\d{4})?$/.test(sZip);
  },

  //////////////////////////////////////////////////////////////////////////////
  //////STATES, ZIPS, LOCATIONS
  //////////////////////////////////////////////////////////////////////////////
  isValidUSState: function (val) {
    let ret = false;
    val = val.toUpperCase();
    const usStates = [
      { name: "ALABAMA", abbreviation: "AL" },
      { name: "ALASKA", abbreviation: "AK" },
      { name: "AMERICAN SAMOA", abbreviation: "AS" },
      { name: "ARIZONA", abbreviation: "AZ" },
      { name: "ARKANSAS", abbreviation: "AR" },
      { name: "CALIFORNIA", abbreviation: "CA" },
      { name: "COLORADO", abbreviation: "CO" },
      { name: "CONNECTICUT", abbreviation: "CT" },
      { name: "DELAWARE", abbreviation: "DE" },
      { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
      { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
      { name: "FLORIDA", abbreviation: "FL" },
      { name: "GEORGIA", abbreviation: "GA" },
      { name: "GUAM", abbreviation: "GU" },
      { name: "HAWAII", abbreviation: "HI" },
      { name: "IDAHO", abbreviation: "ID" },
      { name: "ILLINOIS", abbreviation: "IL" },
      { name: "INDIANA", abbreviation: "IN" },
      { name: "IOWA", abbreviation: "IA" },
      { name: "KANSAS", abbreviation: "KS" },
      { name: "KENTUCKY", abbreviation: "KY" },
      { name: "LOUISIANA", abbreviation: "LA" },
      { name: "MAINE", abbreviation: "ME" },
      { name: "MARSHALL ISLANDS", abbreviation: "MH" },
      { name: "MARYLAND", abbreviation: "MD" },
      { name: "MASSACHUSETTS", abbreviation: "MA" },
      { name: "MICHIGAN", abbreviation: "MI" },
      { name: "MINNESOTA", abbreviation: "MN" },
      { name: "MISSISSIPPI", abbreviation: "MS" },
      { name: "MISSOURI", abbreviation: "MO" },
      { name: "MONTANA", abbreviation: "MT" },
      { name: "NEBRASKA", abbreviation: "NE" },
      { name: "NEVADA", abbreviation: "NV" },
      { name: "NEW HAMPSHIRE", abbreviation: "NH" },
      { name: "NEW JERSEY", abbreviation: "NJ" },
      { name: "NEW MEXICO", abbreviation: "NM" },
      { name: "NEW YORK", abbreviation: "NY" },
      { name: "NORTH CAROLINA", abbreviation: "NC" },
      { name: "NORTH DAKOTA", abbreviation: "ND" },
      { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
      { name: "OHIO", abbreviation: "OH" },
      { name: "OKLAHOMA", abbreviation: "OK" },
      { name: "OREGON", abbreviation: "OR" },
      { name: "PALAU", abbreviation: "PW" },
      { name: "PENNSYLVANIA", abbreviation: "PA" },
      { name: "PUERTO RICO", abbreviation: "PR" },
      { name: "RHODE ISLAND", abbreviation: "RI" },
      { name: "SOUTH CAROLINA", abbreviation: "SC" },
      { name: "SOUTH DAKOTA", abbreviation: "SD" },
      { name: "TENNESSEE", abbreviation: "TN" },
      { name: "TEXAS", abbreviation: "TX" },
      { name: "UTAH", abbreviation: "UT" },
      { name: "VERMONT", abbreviation: "VT" },
      { name: "VIRGIN ISLANDS", abbreviation: "VI" },
      { name: "VIRGINIA", abbreviation: "VA" },
      { name: "WASHINGTON", abbreviation: "WA" },
      { name: "WEST VIRGINIA", abbreviation: "WV" },
      { name: "WISCONSIN", abbreviation: "WI" },
      { name: "WYOMING", abbreviation: "WY" },
    ];

    for (let i = 0; i < usStates.length; i++) {
      if (val === usStates[i]["abbreviation"]) {
        ret = true;
      }
    }
    return ret;
  },

  //////////////////////////////////////////////////////////////////////////////
  //////DATE TIME
  //////////////////////////////////////////////////////////////////////////////
  toHHMMSS: function (val) {
    var sec_num = parseInt(val, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours === 0) {
      hours = "";
    } else if (hours < 10) {
      hours = "0" + hours + ":";
    } else {
      hours = hours + ":";
    }
    /*
        if (minutes === 0) {
            minutes = "";
        } else if (minutes < 10) {
            minutes = "0" + minutes + ":";
        } else {
            minutes = minutes + ":";
        }
        */
    if (minutes < 10) {
      minutes = "0" + minutes + ":";
    } else {
      minutes = minutes + ":";
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + minutes + seconds;
  },

  calcAge: function (birth_year, birth_month, birth_day) {
    //pass in y,d,m
    const today_date = new Date();
    const today_year = today_date.getFullYear();
    const today_month = today_date.getMonth();
    const today_day = today_date.getDate();
    let age = today_year - birth_year;
    if (today_month < birth_month - 1) {
      age--;
    }
    if (birth_month - 1 == today_month && today_day < birth_day) {
      age--;
    }
    if (isNaN(age)) age = -1;
    return age;
  },

  makeValidMonth: function (v) {
    if (isNumeric(v)) {
      v = parseFloat(v);
      if (v <= 0 || v > 12) {
        v = -1;
      } else {
        v = addZeroIfSingle(v);
      }
    } else {
      v = -1;
    }
    return v;
  },
  makeValidDay: function (v) {
    if (isNumeric(v)) {
      v = parseFloat(v);
      if (v <= 0 || v > 31) {
        v = -1;
      } else {
        v = addZeroIfSingle(v);
      }
    } else {
      v = -1;
    }
    return v;
  },
  makeValidYear: function (v) {
    if (isNumeric(v)) {
      v = parseFloat(v);
      if (v < 1900 || v > 2020) {
        v = -1;
      } else {
        v = addZeroIfSingle(v);
      }
    } else {
      v = -1;
    }
    return v;
  },

  getTimeZone: function (dt) {
    return /\((.*)\)/.exec(dt.toString())[1];
  },

  scIsDateReached: function (yy, mm, dd, h, m, s, hours_timezone_adjust) {
    if (yy === null) yy = 1970;
    if (mm === null) mm = 1;
    if (dd === null) dd = 1;
    if (h === null) h = 0;
    if (m === null) m = 0;
    if (s === null) s = 0;
    mm -= 1; //month starts at 0

    var od = new Date(yy, mm, dd, h, m, s);
    var cd = new Date();

    if (
      hours_timezone_adjust === "undefined" ||
      hours_timezone_adjust === undefined
    ) {
      hours_timezone_adjust = "";
    }

    if (hours_timezone_adjust !== "") {
      var offset = cd.getTimezoneOffset();
      offset = -1 * (offset / 60); //negative is east of GMT, positive is west of GMT
      offset = offset - hours_timezone_adjust; //our adjust uses the regular with negative for west of GMT
      //alert(offset);
      //8 is LA, 7 without daylight savings

      od.addHours(offset);
    }

    if (cd >= od) {
      var ret = true;
    } else {
      var ret = false;
    }
    return ret;
  },

  //adds "dt-reached" class to elements that have "check-dt-reached" class
  //<div class="check-dt-reached" dt="yy-mm-dd-h-m-s-tzj">
  //selector default is check-dt-reached
  scAddIsDateReachedFlag: function (selector) {
    $(selector).each(function (index) {
      $(this).removeClass("dt-reached");

      var dt = $(this).attr("dt");
      var dt_arr = dt.split("-");
      var yy = dt_arr[0];
      var mm = dt_arr[1];
      var dd = dt_arr[2];
      var h = dt_arr[3];
      var m = dt_arr[4];
      var s = dt_arr[5];
      var hours_timezone_adjust = dt_arr[6];
      if (scIsDateReached(yy, mm, dd, h, m, s, hours_timezone_adjust)) {
        $(this).addClass("dt-reached");
      }
    });
  },
  scAddIsDateReachedFlagMostRecent: function (selector) {
    let cont = true;
    $(selector).each(function (index) {
      if (cont) {
        let dt = $(this).attr("dt");
        let dt_arr = dt.split("-");
        let yy = dt_arr[0];
        let mm = dt_arr[1];
        let dd = dt_arr[2];
        let h = dt_arr[3];
        let m = dt_arr[4];
        let s = dt_arr[5];
        let hours_timezone_adjust = dt_arr[6];
        if (
          siteCommon.scIsDateReached(yy, mm, dd, h, m, s, hours_timezone_adjust)
        ) {
          $(selector).removeClass("dt-reached");
          $(this).addClass("dt-reached");
        } else {
          cont = false;
        }
      }
    });
  },

  //////////////////////////////////////////////////////////////////////////////
  //////SCROLL RELATED
  //////////////////////////////////////////////////////////////////////////////
  isScrolledIntoView: function (elem, type) {
    let docViewTop = $(window).scrollTop();
    let docViewBottom = docViewTop + $(window).height();
    let h = $(elem).height();
    let wh = docViewBottom - docViewTop;
    let elemTop = $(elem).offset().top;
    let elemBottom = elemTop + h;
    let ret = "";
    if (type === "above_top") {
      ret = elemTop <= docViewTop - site.scrollto_offset;
    } else if (type === "above_middle") {
      let docViewMiddle = docViewTop + $(window).height() / 2;
      ret = docViewMiddle >= elemTop;
    } else {
      //onpage - nothing off
      //var ret = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))
      if (h > wh) {
        ret = elemBottom <= docViewBottom + wh / 2;
      } else {
        ret = elemBottom <= docViewBottom && elemTop >= docViewTop;
      }
    }
    return ret;
  },

  //SCROLLS TO TOP OF PAGE
  //USE in SITE LOAD
  //scOpenTopOfPage(true);
  scOpenTopOfPage: function (doTimerOpens = true) {
    window.scrollTo(0, 1);
    document.body.scrollTop = 1;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    $("#content").scrollTop(0);
    $(this).scrollTop(0);
    if (doTimerOpens) {
      //$(window).on('beforeunload', function(){
      //scOpenTopOfPage(false);
      //});
      $(window).on("unload", function () {
        siteCommon.scOpenTopOfPage(false);
      });
      setTimeout(function () {
        siteCommon.scOpenTopOfPage(false);
      }, 30);
    }
  },

  //////////////////////////////////////////////////////////////////////////////
  //////ANIMATION RELATED
  //////////////////////////////////////////////////////////////////////////////
  addSmoothScroll: function () {
    //smoothscroll
    $(function () {
      $('a[href*="#"]:not([href="#"])').click(function () {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            $("html, body").animate(
              {
                scrollTop: target.offset().top,
              },
              1000
            );
            return false;
          }
        }
      });
    });
  },

  //cssFilterTween
  //onUpdate:cssFilterTween,onUpdateParams: ["{self}","grayscale", 100, 0]
  cssFilterTween: function (tl, filter, start, end) {
    var units = ["px", "deg", "%"];
    var tlp = (tl.progress() * 100) >> 0;
    switch (filter) {
      case "blur":
        //filter = "blur";
        if (start < end) {
          var inc = start + (Math.abs(start - end) / 100) * tlp;
        } else {
          var inc = start - (Math.abs(start - end) / 100) * tlp;
        }
        gsap.set(tl.target, {
          "-webkit-filter": "blur(" + inc + units[0] + ")",
          filter: "blur(" + inc + units[0] + ")",
        });
        break;
      case "hue-rotate":
        //filter = "hue-rotate"
        var tlp = (tl.progress() * 100) >> 0;
        if (start < end) {
          var inc = start + (Math.abs(start - end) / 100) * tlp;
        } else {
          var inc = start - (Math.abs(start - end) / 100) * tlp;
        }
        gsap.set(tl.target, {
          "-webkit-filter": "hue-rotate(" + inc + units[1] + ")",
          filter: "hue-rotate(" + inc + units[1] + ")",
        });
        break;
      default:
        //everything else is %
        var tlp = (tl.progress() * 100) >> 0;
        if (start < end) {
          var inc = start + (Math.abs(start - end) / 100) * tlp;
        } else {
          var inc = start - (Math.abs(start - end) / 100) * tlp;
        }
        gsap.set(tl.target, {
          "-webkit-filter": filter + "(" + inc + units[2] + ")",
          filter: filter + "(" + inc + units[2] + ")",
        });
        break;
    }
  },

  //////////////////////////////////////////////////////////////////////////////
  //////JS FILE TYPE & SIZE
  //////////////////////////////////////////////////////////////////////////////
  formatFileSize: function (bytes) {
    if (typeof bytes !== "number") {
      return "";
    }
    if (bytes >= 1000000000) {
      return (bytes / 1000000000).toFixed(2) + " GB";
    }
    if (bytes >= 1000000) {
      return (bytes / 1000000).toFixed(2) + " MB";
    }
    return (bytes / 1000).toFixed(2) + " KB";
  },

  scGetFileSize: function (fileid, type) {
    //type = kb, mb, gb
    //returns KB
    var iSize = $(fileid)[0].files[0].size / 1024;
    if (type === "gb") {
      iSize = Math.round((iSize / 1024 / 1024) * 100) / 100;
    } else if (type === "mb") {
      iSize = Math.round((iSize / 1024) * 100) / 100;
    } else {
      iSize = Math.round(iSize * 100) / 100;
    }
    return iSize;
  },
  getExtension: function (filename) {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  },

  isImage: function (filename) {
    let ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "bmp":
        return true;
    }
    return false;
  },

  scStripYouTubeID: function (url) {
    let video_id = url.split("v=")[1];
    let ampersandPosition = video_id.indexOf("&");
    if (ampersandPosition !== -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }
    return video_id;
  },

  //Force links to blank, unless they are part of an exclude list - then they are self managed
  //siteCommon.makeExternalLinksBlank(['my-domain.com']) - leave off http
  makeExternalLinksBlank(excludeArray = [], hook = "") {
    $('a[href^="https://"]').attr("target", "_blank");
    $('a[href^="http://"]').attr("target", "_blank");
    /*
        if (excludeArray.length) {
            for (const v of excludeArray) {
                let sel = null
                if (hook) {
                    let subsel = $(hook)
                    sel = $('a', subsel).not('[href*="' + v + '"]')
                } else {
                    sel = $('a').not('[href*="' + v + '"]')
                }
                $(sel).attr('target', '_blank')
                $('a[href^="https://"]',sel).attr('target', '_blank')
                $('a[href^="http://"]',sel).attr('target', '_blank')
            }

        } else {
            if (hook) {
                let subsel = $(hook)
                $('a[href^="https://"]',subsel).attr('target', '_blank')
                $('a[href^="http://"]',subsel).attr('target', '_blank')
            } else {
                $('a[href^="https://"]').attr('target', '_blank')
                $('a[href^="http://"]').attr('target', '_blank')
            }

        }

         */
  },
};
export default siteCommon;
