let customDropdowns = {
  init() {
    // console.log("in customDropdowns.init function");

    $(".field-dropdown-box .div-option").off("click");
    $(".device-notmobile .field-dropdown-box .field-box").off("mouseenter");
    $(".device-notmobile .field-dropdown-box .field-box").off("mouseleave");
    $(".device-mobile .field-dropdown-box .field-box").off("click");

    $(".field-dropdown-box .div-option").on("click", customDropdowns.select);

    $(".device-notmobile .field-dropdown-box .field-box").on(
      "mouseenter",
      customDropdowns.over
    );

    $(".device-notmobile .field-dropdown-box .field-box").on(
      "mouseleave",
      customDropdowns.out
    );
    $(".device-mobile .field-dropdown-box .field-box").on(
      "click",
      customDropdowns.toggle
    );
  },
  custDropdownInitPrefix(idprefix) {
    $(idprefix + " .div-option").unbind();
    $(".device-notmobile " + idprefix + " .field-box").unbind();
    $(".device-notmobile " + idprefix + " .field-box").unbind();
    $(".device-mobile " + idprefix + " .field-box").unbind();

    $(idprefix + " .div-option").on("click", customDropdowns.select);
    $(".device-notmobile " + idprefix + " .field-box").on(
      "mouseenter",
      customDropdowns.over
    );
    $(".device-notmobile " + idprefix + " .field-box").on(
      "mouseleave",
      customDropdowns.out
    );
    $(".device-mobile " + idprefix + " .field-box").on(
      "click",
      customDropdowns.toggle
    );
  },

  select: function () {
    var t = $(this).parent().parent().parent();
    var val_disp = $(this).html();
    var val = $(this).attr("value");
    var placeholder = $(".div-select-selected-display", t).attr("placeholder");

    // remove the .div-option that was selected from the dropdown
    $(this).siblings().removeClass("hidden");
    setTimeout(() => {
      $(this).addClass("hidden");
    }, 500);

    $(".div-select-selected-display", t).html(val_disp);
    $(".div-select-selected-display", t).attr("selected_val", val);
    if ($("body").hasClass("device-notmobile")) {
      customDropdowns.hide(t);
    }

    if (placeholder === val_disp || placeholder === val) {
      $(".div-select-selected-display", t).removeClass("option-is-selected");
    } else {
      $(".div-select-selected-display", t).addClass("option-is-selected");
    }
  },
  over: function () {
    customDropdowns.show(this);
  },
  out: function () {
    customDropdowns.hide(this);
  },
  toggle: function () {
    if ($(this).hasClass("over")) {
      customDropdowns.hide(this);
    } else {
      customDropdowns.show(this);
    }
  },
  show(t) {
    var th = parseFloat($(".div-options", t).height());
    th -= 1;
    //match 205 to the css max-height
    var maxH = 405;
    if (th === 0) {
      th = maxH;
    } else if (th > maxH) {
      th = maxH;
    }
    $(".div-select", t).css("height", th);
    $(t).addClass("over");
  },
  hide(t) {
    $(".div-select", t).css("height", 0);
    $(t).removeClass("over");
  },
};
export default customDropdowns;
