import siteCommon from "site/site-common";
let siteScroll = {
  init: function () {
    ////////////////////////////
    $(window).on("scroll", siteScroll.scroll);
    $(window).on("resize", siteScroll.scroll);

    //siteScroll.setHeaderPusher(false)
    siteScroll.scroll();
  }, //init

  //////////////////////////////////////////////////////////////////////////////////////////
  scroll: function () {
    var scrollTop = $(window).scrollTop();
    var headershrink_h = 0;
    if (scrollTop > headershrink_h) {
      $("body").addClass("miniheader");
    } else {
      $("body").removeClass("miniheader");
    }
    //siteScroll.setHeaderPusher(true)

    if ($("body").hasClass("home")) {
      let activeId = "";
      $(".panels .panel").each(function () {
        let elem = $(this);
        if (siteCommon.isScrolledIntoView(elem, "above_middle")) {
          activeId = elem.attr("data-film-id");
        }
      });
      $(".sidenav-links-hook li").removeClass("active");
      if (activeId) {
        $("#filmnav-" + activeId).addClass("active");
      } else {
        $(".sidenav-links-hook li:first-child").addClass("active");
      }
    } else if ($("body").hasClass("film")) {
      let activeId = "";
      $(".film-blocks .film-block").each(function () {
        let elem = $(this);
        if (siteCommon.isScrolledIntoView(elem, "above_middle")) {
          activeId = elem.attr("id");
        }
      });
      $(".sidenav-links li").removeClass("active");
      if (activeId) {
        $("#filmnav-" + activeId).addClass("active");
      } else {
        $(".sidenav-links li:first-child").addClass("active");
      }
    }
  },
};
export default siteScroll;
