import { gsap, Power3, CSSPlugin, ScrollToPlugin } from "imports/gsap/all";
// gsap.registerPlugin(ScrollToPlugin);

var site_mobile_nav = {
  init: function () {
    var self = this;

    //hamburger
    $("#mobile-nav-handle").click(self.doHamburerToggle);

    //SIDENAV: add panel selector and set the first panel
    $("#mobile-nav-content .mobile-sidenav-li").on("click", self.panelClick);
    $("#mobile-nav-content .mobile-sidenav-li:first-child").click();

    //TABS: add handle & automatically set the first tab in any panel that has a tab
    $("#mobile-nav-content .content.tabbed .tabs .tab-item").on(
      "click",
      self.tabClick
    );
    $(
      "#mobile-nav-content .content.tabbed .tabs .tab-item:first-child"
    ).click();

    //TOGGLER: for H2 col sections
    $("#mobile-nav-content .col-links h2").click(self.colToggle);

    //added by karl 4/1/24
    $("#film-nav .mobile-clicker").click(self.mobileNavOpen);

    site_mobile_nav.overlayHide();
  }, //init

  doHamburerToggle: function () {
    if ($("#mobile-nav-hamburger .hamburger-btn").hasClass("is-active")) {
      site_mobile_nav.overlayHide();
    } else {
      site_mobile_nav.overlayShow();
    }
  }, //doHamburerToggle

  // added by karl 4/1/24
  mobileNavOpen: function () {
    $("#film-nav nav").addClass("open");

    var tl = new TimelineMax();
    tl.delay(0);
    var tme = 0.5;
    var stagger = 0.05;

    tl.staggerFromTo(
      "#film-nav2 .items .item.unselected",
      tme,
      {
        x: -25,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        ease: Power3.easeOut,
      },
      stagger
    );
  },
  mobileNavClose: function () {
    $("#film-nav nav").removeClass("open");
  },

  overlayShow: function () {
    $("#mobile-nav-handle").addClass("is-active");
    $("#mobile-nav-hamburger .hamburger-btn").addClass("is-active");
    $("#mobile-nav-overlay").removeClass("hidden");
    $("#mobile-nav").show();
    $("body").addClass("no-overflow");

    // var tl = new TimelineMax();
    var tl = new gsap.timeline();
    tl.delay(0);
    var tme = 0.5;
    var stagger = 0.05;

    tl.staggerFromTo(
      "#mobile-nav-content .revealer",
      tme,
      {
        x: -25,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        ease: Power3.easeOut,
      },
      stagger
    );

    tl.staggerFromTo(
      "#mobile-nav-footer .revealer2",
      tme,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: Power3.easeOut,
      },
      stagger,
      "-=0.45"
    );
  },
  overlayHide: function () {
    $("#mobile-nav-handle").removeClass("is-active");
    $("#mobile-nav-hamburger .hamburger-btn").removeClass("is-active");
    $("#mobile-nav-overlay").addClass("hidden");
    $("#mobile-nav").hide();
    $("body").removeClass("no-overflow");
  },

  panelClick: function () {
    var ref = $(this).attr("ref");
    $("#mobile-nav-content .right .panel").hide();

    $("#mobile-nav-content .sidenav li").removeClass("active");
    $(this).addClass("active");

    TweenMax.fromTo(
      $("#mobile-nav-content .right .panel-" + ref),
      0.35,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: Linear.easeNone,
        delay: 0,
      }
    );
    $("#mobile-nav-content .right .panel-" + ref).show();

    site_mobile_nav.ensureFirstColOpen();
  },

  tabClick: function () {
    var ref = $(this).attr("ref");
    var p = $(this).parent().parent().parent();
    $(".tab-content-section", p).hide();

    $(".tabs .tab-item", p).removeClass("active");
    $(this).addClass("active");

    TweenMax.fromTo(
      $('.tab-content-section[ref="' + ref + '"]', p),
      0.35,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: Linear.easeNone,
        delay: 0,
      }
    );
    $('.tab-content-section[ref="' + ref + '"]', p).show();

    site_mobile_nav.ensureFirstColOpen();
  },

  colToggle: function () {
    var p = $(this).parent();
    if ($(p).hasClass("open")) {
      site_mobile_nav.colClose(p);
    } else {
      site_mobile_nav.colOpen(p);
    }
  },
  colOpen: function (p) {
    $("#mobile-nav-content .col-links").removeClass("open");
    $(p).addClass("open");
  },
  colClose: function (p) {
    //only close if there are more than 1
    var p = $(p).parent();
    if ($(".col-links", p).length > 1) {
      $("#mobile-nav-content .col-links").removeClass("open");
    }
    //site_mobile_nav.ensureFirstColOpen();
  },
  ensureFirstColOpen: function () {
    //close all
    $("#mobile-nav-content .col-links").removeClass("open");

    //but make sure first one is open
    $("#mobile-nav-content .panel").find(".col-links:first").addClass("open");
    $("#mobile-nav-content .tab-content-section-holder .tab-content-section")
      .find(".col-links:first")
      .addClass("open");
  },
}; //obj
// site_mobile_nav.init();
export default site_mobile_nav;
