// $(document).ready(function () {
//   var stick_soundtrack = {

import { TweenMax, Power3 } from "imports/gsap";
import siteCommon from "site/site-common";

let soundtrack = {
  total_tracks: 0,
  current_track: -1,
  mp3: null,
  seektime: 0,
  is_playing: false,
  revealed_player: false,

  init: function () {
    var self = this;
    $(window).on("resize", self.resize);
    $(".soundtrack-module .soundtrack-list li").on("click", self.listClick);

    // $("#scrubbar-dot-inner").draggable({
    //   axis: "x",
    //   containment: "parent",
    //   drag: function (event, ui) {
    //     if (
    //       typeof stick_soundtrack.mp3 !== "undefined" &&
    //       stick_soundtrack.mp3 !== null
    //     ) {
    //       $("#scrubbar-dot-inner").removeClass("notdragged");
    //       stick_soundtrack.stopSound();
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   stop: function (event, ui) {
    //     if (
    //       typeof stick_soundtrack.mp3 !== "undefined" &&
    //       stick_soundtrack.mp3 !== null
    //     ) {
    //       var maxw = $("#scrubbar-dot-container").width();
    //       var thex = pxToNum($("#scrubbar-dot-inner").css("left"));
    //       var pct = thex / maxw;
    //       $("#scrubbar-dot-inner").addClass("notdragged");
    //       stick_soundtrack.jumpToPct(pct);
    //     }
    //   },
    // });

    soundtrack.total_tracks = $(
      ".soundtrack-module .soundtrack-list li"
    ).length;
    soundtrack.current_track = -1;

    self.reset();

    soundtrack.resize();
  }, //init

  reset: function () {
    //set all icons to paused
    $(".soundtrack-module .soundtrack-list li").addClass("state_paused");

    $(".soundtrack-module .graphic .wave").hide();
    $(".soundtrack-module .graphic .wave.off").show();
  },

  stopSound: function () {
    if (typeof soundtrack.mp3 !== "undefined" && soundtrack.mp3 !== null) {
      soundtrack.mp3.pause();
      soundtrack.is_playing = false;

      $(".soundtrack-module .graphic .wave").hide();
      $(".soundtrack-module .graphic .wave.off").show();

      soundtrack.setPauseIcon();
    }
  },

  resumeSound: function () {
    if (typeof soundtrack.mp3 !== "undefined" && soundtrack.mp3 !== null) {
      soundtrack.mp3.play();
      soundtrack.is_playing = true;

      $(".soundtrack-module .graphic .wave").hide();
      $(".soundtrack-module .graphic .wave.on").show();

      soundtrack.setPlayIcon();
    }
  },

  playSound: function (mp3file) {
    if (typeof soundtrack.mp3 !== "undefined" && soundtrack.mp3 !== null) {
      soundtrack.mp3.removeEventListener("ended", soundtrack.soundEnded);
      soundtrack.stopSound();
      soundtrack.mp3.currentTime = 0;
    }
    soundtrack.mp3 = new Audio(mp3file);
    soundtrack.mp3.addEventListener("ended", soundtrack.soundEnded);
    soundtrack.mp3.addEventListener("timeupdate", soundtrack.soundUpdate);
    soundtrack.mp3.play();
    soundtrack.is_playing = true;

    $(".soundtrack-module .graphic .wave").hide();
    $(".soundtrack-module .graphic .wave.on").show();

    //first time
    if (soundtrack.revealed_player === false) {
      soundtrack.revealed_player = true;
      TweenMax.fromTo(
        ".soundtrack-player",
        2,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: Power3.easeOut,
        }
      );
      //$('.soundtrack-player').css('visibility', 'visible');
    }

    soundtrack.setPlayIcon();
  },

  setPlayIcon: function () {
    //reset others
    $(".soundtrack-module .soundtrack-list li").removeClass("state_playing");
    $(".soundtrack-module .soundtrack-list li").addClass("state_paused");

    //set icon
    $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .addClass("state_playing");
    $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .removeClass("state_paused");
  },

  setPauseIcon: function () {
    //reset others
    $(".soundtrack-module .soundtrack-list li").removeClass("state_playing");
    $(".soundtrack-module .soundtrack-list li").addClass("state_paused");

    //set icon
    $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .removeClass("state_playing");
    $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .addClass("state_paused");
  },

  soundUpdate: function () {
    var disp_ct, disp_duration, pct;
    var ogpct = soundtrack.mp3.currentTime / soundtrack.mp3.duration;
    if (soundtrack.mp3.currentTime > 0) {
      disp_ct = siteCommon.toHHMMSS(soundtrack.mp3.currentTime);
      disp_duration = siteCommon.toHHMMSS(soundtrack.mp3.duration);
      pct = 100 * ogpct;
    } else {
      disp_ct = "";
      disp_duration = "";
      pct = 0;
    }
    $("#soundtrack-time-current .div-tablecell").html(disp_ct);
    $("#soundtrack-time-duration .div-tablecell").html(disp_duration);
    $("#scrubbar-pct").css("width", pct + "%");
    //$('#scrubbar-dot').css('left',pct+"%");

    var maxw = $("#scrubbar-dot-container").width();
    var thex = ogpct * maxw - 9;
    $("#scrubbar-dot-inner").css("left", thex + "px");
  },

  jumpToPct: function (pct) {
    soundtrack.seektime = parseFloat(soundtrack.mp3.duration * pct);
    soundtrack.mp3.currentTime = soundtrack.seektime;
    soundtrack.mp3.addEventListener("playing", soundtrack.soundPlaying);
    soundtrack.resumeSound();
  },

  soundPlaying: function () {
    soundtrack.mp3.removeEventListener("playing", soundtrack.soundPlaying);
    soundtrack.mp3.currentTime = soundtrack.seektime;
  },

  soundEnded: function () {
    soundtrack.current_track++;
    if (soundtrack.current_track >= soundtrack.total_tracks) {
      soundtrack.current_track = 0;
    }
    soundtrack.setSongFromIndex();
  },

  listClick: function () {
    var newindex = $(".soundtrack-module .soundtrack-list li").index(this);
    if (newindex === soundtrack.current_track) {
      if (soundtrack.is_playing) {
        //stop
        soundtrack.stopSound();
      } else {
        //resume
        soundtrack.resumeSound();
      }
    } else {
      //new track
      soundtrack.current_track = newindex;
      soundtrack.setSongFromIndex();
    }
  },

  setSongFromIndex: function () {
    $(".soundtrack-module .soundtrack-list li").removeClass("active");
    $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .addClass("active");
    var mp3file = $(".soundtrack-module .soundtrack-list li")
      .eq(soundtrack.current_track)
      .attr("mp3");
    soundtrack.playSound(mp3file);
  },

  resize: function (evt) {
    setTimeout(soundtrack.resize2, 500);
  },

  resize2: function () {
    if ($("#film-soundtrack .nano-pane").css("display") !== "none") {
      $("#film-soundtrack .nano-content").css("padding-left", "20px");
    } else {
      $("#film-soundtrack .nano-content").css("padding-left", 0);
    }
  },
};

//obj
// soundtrack.total_tracks = 0;
// soundtrack.current_track = -1;
// soundtrack.mp3 = null;
// soundtrack.seektime = 0;
// soundtrack.is_playing = false;
// soundtrack.revealed_player = false;
// soundtrack.init();
// });
export default soundtrack;
